import '../App.css';
import '../sky.css';
import React, { Component } from 'react';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK, { CoinbaseWalletProvider } from "@coinbase/wallet-sdk";
import logo from '../assets/3.mp4';
import os from '../assets/os.png';
import twitter from '../assets/twitter.png';

var Scroll = require('react-scroll');

var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

let outPut = [];

const ops = () => {
	window.open("https://testnets.opensea.io/collection/citma-5");
}

const tweet = () => {
	window.open("#");
}

const mail = () => {
	window.open("mailto:azhar.sadique@gmail.com");
}

let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let totalSupplyNFT2;
let maxMintNFTs;
let onlyLeft;
let owner;
let publicSale;
let user_input;
let wMintAmount;
let myTokens = [];
let myTokens2 = [];
let adminWhitelistAddresses = [];
let adminPanel = 0;
let adminWhitelistAddresses2 = [];
let maxTokensToBuy = "";
let testingvalue;
let wlMint;
let FinalResult;
let wlMsg = "Whitelist Mint";
let mintStatus = ''
let cost = '';
let wlCost = '';
let max_per_wallet = '';
let publicMintMsg = "Public Mint";
let freeMinted;

let ABI = [
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initNotRevealedUri",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "ApprovalCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalToCurrentOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApproveToCaller",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "BalanceQueryForZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintZeroQuantity",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "OperatorNotAllowed",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TokenIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferFromIncorrectOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToNonERC721ReceiverImplementer",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "URIQueryForNonexistentToken",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "MAX_SUPPLY",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "OPERATOR_FILTER_REGISTRY",
		"outputs": [
			{
				"internalType": "contract IOperatorFilterRegistry",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address[]",
				"name": "receiver",
				"type": "address[]"
			},
			{
				"internalType": "uint256[]",
				"name": "quantity",
				"type": "uint256[]"
			}
		],
		"name": "airdrop",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "contractURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getBaseURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "max_per_wallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			},
			{
				"internalType": "string",
				"name": "email",
				"type": "string"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "notRevealedUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "publicMinted",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "public_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "royaltyInfo",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"name": "setContractURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_MAX_SUPPLY",
				"type": "uint256"
			}
		],
		"name": "setMAX_SUPPLY",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_max_per_wallet",
				"type": "uint256"
			}
		],
		"name": "setMax_per_wallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_notRevealedURI",
				"type": "string"
			}
		],
		"name": "setNotRevealedURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_publicSaleCost",
				"type": "uint256"
			}
		],
		"name": "setPublicSaleCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_receiver",
				"type": "address"
			},
			{
				"internalType": "uint96",
				"name": "_royaltyFeesInBips",
				"type": "uint96"
			}
		],
		"name": "setRoyaltyInfo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleReveal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_public_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenOfOwnerByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "userEmail",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	}
];

let address = "0xb1dBa44F7be3D7967210E342Ca5Ebcf3bc8f4332";

let contract;

class Home extends Component {

	state = {
		walletAddress: "",
		totalSupply: "",
		currentPrice: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		statusError: false,
		statusLoading: false,
		success: false,
		nftMintingAmount: '1',
		totalValue: "",
		presaleValue: "0",
		maxmint: '',
		_adminPanel: 0,
		_adminWhitelistAddresses: [],
		_adminWhitelistAddresses2: [],
		_maxTokensToBuy: "",
		_testingValue: '',
		_wlMint: '',
		_FinalResult: '',
		_wlMsg: 'Free Mint',
		_publicMintMsg: 'Mint Here',
		_mintStatus: false,
		_cost: 0,
		_wlCost: '',
		_wlMintAmount: '',
		_max_per_wallet: '',
		_owner: '',
		_freeMinted: '',
		_no: 0,
		_yes: 0,
		_images: 0,
		_email: '',
		_outPut: []
	}

	async componentDidMount() {

		if (typeof window.ethereum !== 'undefined') {
			console.log('MetaMask is installed!');

			try {

				const web3mm = new Web3(window.ethereum);

				// Request access to user accounts
				await window.ethereum.request({ method: 'eth_requestAccounts' });
				console.log("metamask on PC")
				// Continue with your DApp logic
				const accounts = await web3mm.eth.getAccounts();
				const account = accounts[0];

				// Fetch data from contracts, etc.
				// ...
				// Fetch data from contracts
				contract = new web3mm.eth.Contract(ABI, address);

				// Update your state with the wallet address
				this.setState({ walletAddress: account });

				// Rest of your MetaMask-specific logic can go here
				// ...
				const chainId = 5;

				if (window.ethereum.networkVersion !== chainId) {
					try {
						await window.ethereum.request({
							method: 'wallet_switchEthereumChain',
							params: [{ chainId: web3mm.utils.toHex(chainId) }],
						});
					} catch (err) {
						// This error code indicates that the chain has not been added to MetaMask.
						if (err.code === 4902) {
							await window.ethereum.request({
								method: 'wallet_addEthereumChain',
								params: [
									{
										/*chainName: 'zkSync alpha testnet',
										chainId: web3mm.utils.toHex(chainId),
										nativeCurrency: { name: 'zkSync alpha testnet', decimals: 18, symbol: 'ETH' },
										rpcUrls: ['https://zksync2-testnet.zkscan.io/'],*/

										chainName: 'Goerli Test Network',
										chainId: web3mm.utils.toHex(chainId),
										nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
										rpcUrls: ['https://goerli.infura.io/v3/'],

										/*chainName: 'Rinkeby Test Network',
										chainId: web3mm.utils.toHex(chainId),
										nativeCurrency: { name: 'RinkebyETH', decimals: 18, symbol: 'ETH' },
										rpcUrls: ['https://rinkeby.infura.io/v3/'],*/
										/*chainName: 'Mumbai Testnet',
										chainId: web3mm.utils.toHex(chainId),
										nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
										rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],*/
									},
								],
							});
						}
					}
				}


				try {
					localStorage.setItem('isWalletConnected', true);
				} catch (ex) {
					console.log(ex)
				}

				web3mm.eth.getBalance(account, (error, balance) => {
					if (!error) {
						// Convert balance from Wei to Ether

						if (balance < 1000000000000000) {
							this.setState({ _images: 1 });
						} else {
							this.setState({ _images: 0 });
						}

						const etherBalance = web3mm.utils.fromWei(balance, 'ether');
						console.log(`Wallet balance: ${etherBalance} ETH`);
					} else {
						console.error(`Error fetching balance: ${error}`);
					}
				});

				console.log("populateTable");

				const totalSupply = await contract.methods.totalSupply().call();
				console.log("totalSupply : " + totalSupply);
				for (let tokenId = 1; tokenId <= totalSupply; tokenId++) {
					try {
						// Get the owner's address for the NFT
						const ownerAddress = await contract.methods.ownerOf(tokenId).call();
						console.log("In");
						// Get the email address associated with the owner's address
						const emailAddress = await contract.methods.userEmail(ownerAddress).call();

						// Add the data to the table
						outPut[tokenId - 1] = [ownerAddress, emailAddress];
						this.setState({ _outPut: outPut });
						console.log("_outPut : " + outPut[0]);
					} catch (error) {
						console.error(`Error processing tokenId ${tokenId}: ${error}`);
					}
				}

				totalSupplyNFT = await contract.methods.totalSupply().call();
				this.setState({ totalSupply: totalSupplyNFT });
				console.log("Total Supply:" + totalSupplyNFT);

				max_per_wallet = await contract.methods.max_per_wallet().call();
				this.setState({ _max_per_wallet: max_per_wallet });
				console.log("max_per_wallet:" + max_per_wallet);

				publicSale = await contract.methods.balanceOf(account).call();
				this.setState({ myNFTWallet: publicSale });

				cost = await contract.methods.publicSaleCost().call();
				this.setState({ _cost: cost });
				console.log("cost :" + cost);

				owner = await contract.methods.owner().call();
				this.setState({ _owner: owner });
				console.log("Owner" + owner);


				if (owner == account) {
					console.log("owner : " + owner)
					onlyLeft = 20 - totalSupplyNFT;

					if (mintAmount > onlyLeft) {
						mintAmount = onlyLeft;
					}

					valueOfNFTs = mintAmount * 0;
					wMintAmount = mintAmount;


					this.setState({ nftMintingAmount: mintAmount });

					this.setState({ totalValue: valueOfNFTs });
				} else {
					mintAmount = 1;

					if (totalSupplyNFT == 20) {

						onlyLeft = 20 - publicSale;
						mintAmount = onlyLeft;
						this.setState({ msg: "SOLD OUT" });

					} else {
						mintAmount = 1;
						onlyLeft = 2;

						if (mintAmount > onlyLeft) {
							mintAmount = onlyLeft;
						}
						//mintAmount = onlyLeft;

						valueOfNFTs = mintAmount * this.state._cost;
						wMintAmount = mintAmount;


						this.setState({ nftMintingAmount: mintAmount });

						this.setState({ totalValue: valueOfNFTs });
					}
				}


			} catch (err) {
				console.log("err: " + err);

			}

			//})();

			//.....................................................................//

			// Legacy providers may only have ethereum.sendAsync



		}

	}

	onSubmitMinus = async event => {
		event.preventDefault();

		mintAmount = mintAmount - 1;

		if (mintAmount < 1) {
			mintAmount = 1
		}


		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 20 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;
			wMintAmount = mintAmount;

			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {


			if (totalSupplyNFT < 20) {

				onlyLeft = max_per_wallet - publicSale;

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;


				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });

			}
		}
	}

	onSubmitPlus = async event => {
		event.preventDefault();

		//,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
		mintAmount = mintAmount + 1;

		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 20 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;
			wMintAmount = mintAmount;


			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {

			if (totalSupplyNFT < 20) {

				onlyLeft = 2;
				console.log(onlyLeft);

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;

				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });

			}
		}
	}

	onSubmit2 = async event => {
		event.preventDefault();

		//console.log(this.state.walletAddress);

		console.log("WWWWWWWWWWWWWW");

		try {


			try {


				console.log("minAmount:" + mintAmount);
				console.log("valueOfNFTs:" + valueOfNFTs);
				console.log("cost : " + this.state.totalValue);

				this.setState({ statusError: false, statusLoading: true });
				await contract.methods.mint(mintAmount * 1, this.state._email).send({ gasLimit: 385000, from: this.state.walletAddress, value: this.state.totalValue * 1 });
				this.setState({ statusLoading: false, success: true });
				await new Promise(resolve => setTimeout(resolve, 5000));
				window.location.reload();


			} catch (err) {
				this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
				console.log(err);


			}


		} catch (err) {

			console.log(err);

		}
	}


	walletConnect = async event => {
		event.preventDefault();

		if (typeof window.ethereum !== 'undefined') {
			console.log('MetaMask is installed!');

			try {

				const web3mm = new Web3(window.ethereum);

				// Request access to user accounts
				await window.ethereum.request({ method: 'eth_requestAccounts' });
				console.log("metamask on PC")
				// Continue with your DApp logic
				const accounts = await web3mm.eth.getAccounts();
				const account = accounts[0];

				// Fetch data from contracts, etc.
				// ...
				// Fetch data from contracts
				contract = new web3mm.eth.Contract(ABI, address);

				// Update your state with the wallet address
				this.setState({ walletAddress: account });

				// Rest of your MetaMask-specific logic can go here
				// ...
				const chainId = 5;

				if (window.ethereum.networkVersion !== chainId) {
					try {
						await window.ethereum.request({
							method: 'wallet_switchEthereumChain',
							params: [{ chainId: web3mm.utils.toHex(chainId) }],
						});
					} catch (err) {
						// This error code indicates that the chain has not been added to MetaMask.
						if (err.code === 4902) {
							await window.ethereum.request({
								method: 'wallet_addEthereumChain',
								params: [
									{
										/*chainName: 'zkSync alpha testnet',
										chainId: web3mm.utils.toHex(chainId),
										nativeCurrency: { name: 'zkSync alpha testnet', decimals: 18, symbol: 'ETH' },
										rpcUrls: ['https://zksync2-testnet.zkscan.io/'],*/

										chainName: 'Goerli Test Network',
										chainId: web3mm.utils.toHex(chainId),
										nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
										rpcUrls: ['https://goerli.infura.io/v3/'],

										/*chainName: 'Rinkeby Test Network',
										chainId: web3mm.utils.toHex(chainId),
										nativeCurrency: { name: 'RinkebyETH', decimals: 18, symbol: 'ETH' },
										rpcUrls: ['https://rinkeby.infura.io/v3/'],*/
										/*chainName: 'Mumbai Testnet',
										chainId: web3mm.utils.toHex(chainId),
										nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
										rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],*/
									},
								],
							});
						}
					}
				}


				try {
					localStorage.setItem('isWalletConnected', true);
				} catch (ex) {
					console.log(ex)
				}

				web3mm.eth.getBalance(account, (error, balance) => {
					if (!error) {
						// Convert balance from Wei to Ether

						if (balance < 1000000000000000) {
							this.setState({ _images: 1 });
						} else {
							this.setState({ _images: 0 });
						}

						const etherBalance = web3mm.utils.fromWei(balance, 'ether');
						console.log(`Wallet balance: ${etherBalance} ETH`);
					} else {
						console.error(`Error fetching balance: ${error}`);
					}
				});

				console.log("populateTable");

				const totalSupply = await contract.methods.totalSupply().call();

				for (let tokenId = 1; tokenId <= totalSupply; tokenId++) {
					try {
						// Get the owner's address for the NFT
						const ownerAddress = await contract.methods.ownerOf(tokenId).call();
						console.log("In");

						// Get the email address associated with the owner's address
						const emailAddress = await contract.methods.userEmail(ownerAddress).call();

						// Add the data to the table
						outPut[tokenId - 1] = [ownerAddress, emailAddress];
						this.setState({ _outPut: outPut });
						console.log("_outPut : " + outPut);
					} catch (error) {
						console.error(`Error processing tokenId ${tokenId}: ${error}`);
					}
				}

				totalSupplyNFT = await contract.methods.totalSupply().call();
				this.setState({ totalSupply: totalSupplyNFT });
				console.log("Total Supply:" + totalSupplyNFT);

				max_per_wallet = await contract.methods.max_per_wallet().call();
				this.setState({ _max_per_wallet: max_per_wallet });
				console.log("max_per_wallet:" + max_per_wallet);

				publicSale = await contract.methods.balanceOf(account).call();
				this.setState({ myNFTWallet: publicSale });

				cost = await contract.methods.publicSaleCost().call();
				this.setState({ _cost: cost });
				console.log("cost :" + cost);

				owner = await contract.methods.owner().call();
				this.setState({ _owner: owner });
				console.log("Owner" + owner);


				if (owner == account) {
					console.log("owner : " + owner)
					onlyLeft = 20 - totalSupplyNFT;

					if (mintAmount > onlyLeft) {
						mintAmount = onlyLeft;
					}

					valueOfNFTs = mintAmount * 0;
					wMintAmount = mintAmount;


					this.setState({ nftMintingAmount: mintAmount });

					this.setState({ totalValue: valueOfNFTs });
				} else {
					mintAmount = 1;

					if (totalSupplyNFT == 20) {

						onlyLeft = 20 - publicSale;
						mintAmount = onlyLeft;
						this.setState({ msg: "SOLD OUT" });

					} else {
						mintAmount = 1;
						onlyLeft = 2;

						if (mintAmount > onlyLeft) {
							mintAmount = onlyLeft;
						}
						//mintAmount = onlyLeft;

						valueOfNFTs = mintAmount * this.state._cost;
						wMintAmount = mintAmount;


						this.setState({ nftMintingAmount: mintAmount });

						this.setState({ totalValue: valueOfNFTs });
					}
				}


			} catch (err) {
				console.log("err: " + err);

			}

			//})();

			//.....................................................................//

			// Legacy providers may only have ethereum.sendAsync



		}

	}


	walletDisconnect = async event => {
		event.preventDefault();

		this.setState({ walletAddress: '' });

		try {
			localStorage.setItem('isWalletConnected', false);
		} catch (ex) {
			console.log(ex);
		}

	}

	no = async event => {
		event.preventDefault();

		this.setState({ _no: 1 });
	}

	closeWindow = async event => {
		event.preventDefault();

		this.setState({ _images: 0 });


	}

	handleClick = () => {
		const toAddress = "azhar.sadique@gmail.com"; // Add the recipient's email address here
		const subject = "I need funds for gas fees";
		const body = "My wallet address is " + account;
		const mailtoLink = `mailto:${toAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

		window.location.href = mailtoLink;
	};

	// Sample function to populate the table
	async populateTable() {

		console.log("populateTable");

		const totalSupply = await contract.methods.totalSupply().call();

		for (let tokenId = 1; tokenId <= totalSupply; tokenId++) {
			try {
				// Get the owner's address for the NFT
				const ownerAddress = await contract.methods.ownerOf(tokenId).call();

				// Get the email address associated with the owner's address
				const emailAddress = await contract.methods.userEmail(ownerAddress).call();

				// Add the data to the table
				outPut[tokenId - 1] = [ownerAddress, emailAddress];
				this.setState({ _outPut: outPut });
				console.log("_outPut : " + outPut[0]);

			} catch (error) {
				console.error(`Error processing tokenId ${tokenId}: ${error}`);
			}
		}
	}

	// Function to add data to the table
	addToTable(address, email) {
		const table = document.getElementById('nftTable');
		const row = table.insertRow(-1);
		const addressCell = row.insertCell(0);
		const emailCell = row.insertCell(1);

		addressCell.innerHTML = address;
		emailCell.innerHTML = email;

		console.log("add to table");
	}

	render() {

		return (

			<div class="allWrap">
				<div class="light">

					<div class="cont">

						<div class="headers">

							<div class="headers2">

								<div class="logo"><img class="logoPic" src={logo} /></div>

								<div class="navBar">

								</div>
								<div class="right">

									<div class="icons">

										<div class="discord"><img class="osPic" onClick={ops} src={os} /></div>
										<div class="discord"><img class="osPic" onClick={tweet} src={twitter} /></div>

									</div>

									<div class="connect2">
										{this.state.walletAddress === '' ?
											(<form onSubmit={this.walletConnect}>
												<button class="wallet2">Wallet Connect</button>
											</form>) : (<form onSubmit={this.walletDisconnect}><button class="wallet2" >
												{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}</button></form>)}

									</div>



								</div>

							</div>

						</div>

						<div class="introduction">

							<div class="in2">
								<div class="intro">
									CITMA
								</div>

								<div class="intro2">
									<span class="hlC">boot camp x20</span><br />
									<p></p>
									<p></p>
									Citma paralegal conference 2023<br />
									1x leadership boot camp session<br />
									Unlock the clues to be rewarded<br />
								</div>

								<Element name="mint">
									<div class="nftblockWalletConnectedALL">

										{this.state.walletAddress === '' ? (

											<div class="walletConnect">
												<form onSubmit={this.walletConnect}>
													<button class="button-85" type='submit'>MINT NOW</button>
												</form>
											</div>
										) :
											(< div >

												<form onSubmit={this.freeMint}>
													<div class="placeAndWL">

														<input class="mintingAmount" type="text" name="setwlMintAmount" placeholder={

															this.state.walletAddress == this.state._owner ? (
																20 - this.state.myNFTWallet
															) :

																(this.state._max_per_wallet - this.state.myNFTWallet)} value={this.state._wlMintAmount}
															onChange={event => this.setState({ _wlMintAmount: event.target.value })}></input>

														<button class="btnfos-0-4" type="submit">
															{this.state._wlMsg}</button>
													</div>
												</form>

											</div>)
										}
									</div>
								</Element>
							</div>
							{this.state.walletAddress === '' ?
								(
									<div class="nftPicDiv">
										<video class="nftPic" src={logo} alt='logo' autoPlay loop playsInline muted />
									</div>
								) : (

									(<div class="mintDiv">
										<div class="totalSupply">{this.state.totalSupply} / 20</div>
										{this.state._max_per_wallet == this.state.myNFTWallet ?

											(<div class="price"><div>Limit Reached!</div></div>) :
											(<div class="price"><div>Free Mint</div></div>)}

										<div class="minting_count_button">
											<div class="center">
												<form onSubmit={this.onSubmitMinus}>
													<button class="btnfos-0-2" type="submit">-</button>
												</form>
											</div>

											<div>
												<div class="nftminter2">{this.state.nftMintingAmount}</div>
											</div>


											<div class="center">
												<form onSubmit={this.onSubmitPlus}>
													<button class="btnfos-0-2-2" type="submit">+</button>
												</form>
											</div>
										</div>

										<form onSubmit={this.onSubmit2}>
											<div class="mintbuttondiv">
												<div>
													<input placeholder='email' type='email' required value={this.state._email}
														onChange={event => this.setState({ _email: event.target.value })}></input>

													<button class="btnfos-0-3" type="submit">
														Mint Now</button>

												</div>
											</div>
										</form>
										<div>

											{this.state.statusError ? (
												<div class="errorMessage">
													<div >Sorry, something went wrong <br /> please try again later</div>
												</div>)
												: null}

											{this.state.statusLoading ? (
												<div class="loadingContainer">
													<div>
														<div class="loadingText">Minting your NFT</div>
													</div>
												</div>)
												: null}

											{this.state.success ? (

												<div><div class="successfully">MINTING SUCCESSFUL!</div>
												</div>)
												: null}

										</div></div>

									)
								)}
						</div>
					</div>

					<div class="formMain2-">
						<div class="formMain-3">
							<div class='refH'>Do you already have MetaMask installed?</div>
							<div class="btn2">
								<button class="register" onClick={() => window.location.href = 'mint'}>Yes</button>
								<button class="connectBtn" onClick={() => window.location.href = 'metamask'}>
									<span class="initialText">No</span>
									<span class="hoverText">Learn</span>
								</button>
							</div>
						</div>
					</div>

				</div >
			</div >)
	}
}

export default Home;

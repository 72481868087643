import '../App.css';
import React, { Component } from 'react';
import back from '../assets/undo.png';

var $ = require("jquery");

class Matamask extends Component {

  render() {
    return (
      <div class="allWrap2">
      <div class="light">
        <div clas="videoMain">
          <div class="video-container">
            <iframe src="https://www.youtube.com/embed/YVgfHZMFFFQ?si=7cL3T5iKQUrSobLC" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>

          <div class="video-container2">
            <iframe src="https://www.youtube.com/embed/Af_lQ1zUnoM?si=ey0COB7eRkqBhusS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>

        </div>
        <img class="back" onClick={() => window.location.href = '/'} src={back}/>
        </div>

      </div>
    );
  }
}

export default Matamask;
